<template>
  <div id="site-settings-social-media-links">
    <p class="helper-text">
      {{ $t("DRAG_DROP_REARRANGE") }}
    </p>

    <el-row type="flex" justify="end" align="middle" class="status-row">
      <span class="el-form-item__label text-bold">{{ $t('STATUS') }}:</span>
      <el-select 
        v-model="allStatus"
        :class="getStatusClass(allStatus) + (isMobile ? ' w-100': '')">
        <el-option
          v-for="item in allSelectableStatusListFor(allStatus)"
          :key="item.value"
          :label="$t(item.value)"
          :value="item.value"
        />
      </el-select>
    </el-row>

    <el-table-draggable
      @input="onDragEnd"
      handle=".drag-handle"
    >
      <el-table
        v-loading="isLoading"
        row-key="id"
        :data="list"
      >
        <el-table-column width="30">
          <span 
            v-if="!isMobile"
            class="drag-handle"
          >
            ::
          </span>
        </el-table-column>
        <el-table-column>
          <template slot-scope="{row}">
            <el-row
              :type="isMobile ? '' : 'flex'"
              class="social-media-row"
            >
              <el-row 
                type="flex" 
                align="middle"
                class="social-media-name-box"
              >
                <div class="icon-wrapper">
                  <img 
                    class="custom-icon social-media-icon"
                    :src="row.photo.upload.url"
                  />
                </div>
                <span>
                  {{ row | translate }}
                </span>
              </el-row>
              <div style="position: relative">
                <el-input
                  class="link-input"
                  :class="{ 'error-border': row._errorMsg }"
                  v-model="row.name"
                  @input="validateAllFields"
                  @blur="validateAllFields"
                  :placeholder="$t('SOCIAL_LINK_PLACEHOLDER')"
                >
                  <i
                    class="el-icon-close el-input__icon pointer"
                    slot="suffix"
                    @click="row.name = ''"
                  />
                </el-input>

                <span
                  :key="'error_'+errorKey"
                  class="error-msg"
                >
                  {{ row._errorMsg }}
                </span>
              </div>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
    </el-table-draggable>

    <page-footer
      :isLoading="isLoading"
      :handleBackTo="exit"
      :handleSubmit="handleSubmit"
      :submitBtnText="$t('SAVE')"
    />

  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep"
import ElTableDraggable from "el-table-draggable";
import PageFooter from "@/components/page-footer.vue";
import { checkUrl } from "@/utils/helpers"
import { getSocialMediaList, bulkUpdateSocialMedia } from "@/api/site-settings"
import { generalMixin } from "@/utils/general-mixin";

export default {
  name: "SocialMediaLinks",
  components: {
    ElTableDraggable, PageFooter
  },
  mixins: [generalMixin],
  data() {
    return {
      isLoading: false,
      errorKey: 0,
      haveError: false,
      list: []
    }
  },
  computed: {
    allStatus: {
      get() {
        return this.list[0]?.status
      },
      set(val) {
        this.list.forEach((el) => {
          el.status = val
        })
      }
    }
  },
  methods: {
    exit() {
      this.goTo("/bo/site-settings");
    },
    onDragEnd(result) {
      this.list = cloneDeep(result)
    },
    handleSubmit() {
      this.validateAllFields()

      if (this.haveError) return
      this.isLoading = true

      const payload = cloneDeep(this.list).map((el, index) => {
        el.sequences = index
        return el
      })

      bulkUpdateSocialMedia({ grList: payload })
      .then((res) => {
        if (res.status !== "ok") return

        this.$notify({
          type: 'success',
          message: this.$t("UPDATE_SUCCESSFULLY"),
          duration: this.NOTIFY_DURATION,
          position: this.NOTIFY_POSITION
        })
        this.exit()
        this.$store.dispatch('app/getAllSiteSettings') // to reflect the changes
      })
      .finally(() => [
        this.isLoading = false
      ])

    },
    getList() {
      this.isLoading = true

      getSocialMediaList().then((res) => {
        if (res.status !== "ok") return

        this.list = res.result.list.sort((a, b) => a.sequences - b.sequences)

        this.list.forEach((el) => {
          el.icon = "icon-" + el.code
        })

      })
      .finally(() => {
        this.isLoading = false
      })
    },
    validateAllFields() {
      this.haveError = false
      this.list.forEach((el) => {
        el._errorMsg = null

        if (el.name && checkUrl(el.name) !== true) {
          el._errorMsg = this.$t("URL_INVALID")
          this.haveError = true
        }
      })

      this.errorKey++
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";

#site-settings-social-media-links {
  background-color: $white;
  padding: 1.5rem;

  .icon-wrapper {
    background-color: $black;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    >img {
      width: 30px;
      height: 30px;
      background-size: 30px 30px;
    }
  }

  .helper-text {
    color: #00000080;
  }

  .drag-handle {
    font-size: 23px;
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    &:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }
  }

  .social-media-row {
    margin: 15px auto 25px;
    height: 40px;
    >* {
      margin-right: 10px;
    }

    input {
      height: 100%;
      padding-right: 30px;
    }

    .link-input-wrapper {
      height: 100%;
      margin-bottom: 0;
    }
    .link-input {
      width: 25rem;
      height: 100%;
    }

    .social-media-name-box,
    input {
      border: 1px solid #0000001A;
      border-radius: 5px;
    }

    .social-media-name-box {
      background-color: $white;
      width: 10rem;
      padding: 5px;
      >* {
        margin-right: 10px;
      }
    }
  }

  .error-msg {
    position: absolute;
    bottom: -20px;
    left: 6px;
    color: #ff4949;
  }

  .error-border {
    input {
      border-color: #ff4949;
    }
  }

}

.mobile #site-settings-social-media-links {
  .social-media-name-box {
    width: 100%;
  }
  .social-media-row {
    height: 100%;
  }
  .social-media-row > * {
    margin-right: 0;
    height: 100%;
  }
  .link-input,
  input {
    width: 100%;
    min-height: 40px;
  }
  .cell {
    margin-bottom: 0.125rem;
  }
}
</style>